
$gold: #edc162 !default;
$danger: $gold !default;
$light: darken(#f4f1f0, 10%) !default;
$dark: #233440 !default;
$warning: #ffa107 !default;
$spacer: 1rem !default;
$primary: $dark !default;
$secondary: $gold !default;
$body-color: $dark !default;
$body-bg: #ffffff !default;
$enable-rounded: true !default;
$carousel-control-width: 5%;
//$modal-content-bg: $dark !default;
//$modal-content-border-color: rgba($gold, .2) !default;
$headings-font-weight: 500 !default;
$headings-margin-bottom: $spacer !default;
$hr-color: $dark !default;
//$link-color: $gold !default;
$navbar-light-color:                $dark !default;
$navbar-light-hover-color:          $dark !default;
$navbar-light-active-color:         $dark !default;
$navbar-light-disabled-color:       $dark !default;
$navbar-light-toggler-border-color: $dark !default;
$font-size-base:              1rem !default;
$h1-font-size:                $font-size-base * 3 !default;
$h2-font-size:                $font-size-base * 2 !default;
$h3-font-size:                $font-size-base * 2 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.5 !default;
$h6-font-size:                $font-size-base !default;
$offcanvas-bg-color:             #fff !default;
$offcanvas-color:          $dark !default;
//$modal-content-bg:            $dark !default;
//$modal-content-color:         $light !default;
$btn-close-color:             $light !default;
$carousel-control-color:             $gold !default;
$carousel-control-opacity:           .9 !default;
$carousel-control-hover-opacity:     1 !default;
$carousel-indicator-active-bg:       $gold !default;
$carousel-indicator-height:          8px !default;
$carousel-indicator-width:           8px !default;
$carousel-indicator-hit-area-height: 15px !default;
$carousel-indicator-spacer:          4px !default;
$carousel-indicator-transition:      all .6s ease !default;

html {
  scroll-behavior: smooth;
}

@import '~bootstrap/scss/bootstrap';
@import 'flag-icons/sass/flag-icons';

h1, h3 {
  @extend .fw-bold;
}

.carousel-indicators [data-bs-target] {
  background: none !important;
  span {
    @extend .rounded;
    @extend .d-flex;
    flex: 0 1 auto;
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    padding: 0;
    text-indent: -999px;
    background-color: $carousel-indicator-active-bg !important;
    background-clip: padding-box;
    border: 0;
    @include transition($carousel-indicator-transition);
  }
  &.active {
    width: $carousel-indicator-width * 5;
    height: $carousel-indicator-height * 1;
    //margin-top: -$carousel-indicator-height / 2;
  }
}

.navbar-cart-icon {
  @extend .navbar-toggler-icon;
  background-image: url('/public/images/cart.svg');
  background-size: 75%;
}

.navbar-info-icon {
  @extend .navbar-toggler-icon;
  background-image: url('/public/images/info.svg');
  background-size: 75%;
}

.navbar-light {
  .navbar-cart-icon {
    background-image: url('/public/images/cart.svg');
  }
  .navbar-info-icon {
    background-image: url('/public/images/info.svg');
  }
}

.more {
  max-height: 350px;
  overflow: hidden;
}

.fa-check {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center center;
  background-image: url(escape-svg("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 448 512'><!--!Font Awesome Pro 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2024 Fonticons, Inc.--><path fill='#{$dark}' d='M443.3 100.7c6.2 6.2 6.2 16.4 0 22.6l-272 272c-6.2 6.2-16.4 6.2-22.6 0l-144-144c-6.2-6.2-6.2-16.4 0-22.6s16.4-6.2 22.6 0L160 361.4 420.7 100.7c6.2-6.2 16.4-6.2 22.6 0z'/></svg>"));
}

.text-danger {
  color: #dc3545 !important;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  @extend .text-danger;
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545 !important;
}

.offcanvas {
  a:not(.btn) {
    @extend .text-light;
    @extend .text-decoration-none;
    @extend .fw-bold;
  }
  .btn-dark {
    @extend .btn-light;
  }
}

.primary-button {
  @extend .btn;
  @extend .btn-danger;
  @extend .stretched-link;
  @extend .d-flex;
  @extend .d-sm-inline-flex;
  @extend .align-items-center;
  @extend .justify-content-center;
  color: $dark;
  &:hover {
    color: $dark;
  }
  &:before {
    display: inline-block;
    width: $btn-line-height * $btn-font-size * 0.8;
    height: $btn-line-height * $btn-font-size * 0.8;
    margin-right: $btn-padding-x * 0.5;
    background: url('/public/images/arrow-right.svg') no-repeat;
    content: '';
  }
}

.card-row {
  @extend .row;
  &.card-row-col-2 {
    @extend .row-cols-md-2;
    @extend .row-cols-xl-4;
    .card-col {
      .card {
        .card-inner {
          @extend .flex-md-row;
          @extend .flex-xl-column;
          @extend .align-items-md-start;
          @extend .align-items-xl-stretch;
          .card-body-col {
            @extend .align-self-stretch;
          }
          .card-image-col {
            @extend .col-md-4;
            @extend .col-xl-12;
          }
        }
      }
    }
  }
  .card-col {
    @extend .col-md;
    @extend .d-flex;
    @extend .py-3;
    .card {
      h3 {
        @extend .h5;
      }
      table {
        @extend .small;
      }
      .card-inner {
        @extend .d-flex;
        @extend .flex-md-column;
        @extend .flex-grow-1;
        @extend .align-items-start;
        @extend .align-items-md-stretch;
        .card-image-col {
          @extend .col-4;
          @extend .col-md-12;
          .card-img-top {
            @extend .overflow-hidden;
          }
        }
        .card-body-col {
          @extend .d-flex;
          @extend .flex-grow-1;
        }
      }
    }
  }
}

.align-items-center-light {
  @extend .align-items-center;
  @extend .bg-light;
  @extend .text-dark;
  @extend .my-3;
}

.align-items-center-dark {
  @extend .align-items-center;
  @extend .bg-dark;
  @extend .text-light;
  @extend .my-3;
}

.text-gold {
  @extend .text-secondary;
  background-image: linear-gradient(47deg,#efcd4d,#ae7c3a,#8f4407);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#top {
  transition-property: opacity;
  transition-duration: 600ms;
  transition-timing-function: ease-in-out;
}

#top-loader {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
  svg {
    animation-duration: 1000ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-name: rotate;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

#sticky-header {
  transition-property: opacity;
  transition-duration: 300ms;
  transition-timing-function: ease-in-out;
}

main {
  transition-property: opacity;
  transition-duration: 600ms;
  transition-timing-function: ease-in-out;
}

#pointer-down {
  transition-property: all;
  transition-duration: 1000ms;
}

#sticky-header .navbar-brand > img {
  height: 40px;
}

@include media-breakpoint-up(lg) {
  #sticky-header .navbar-brand > img {
    height: 70px;
  }
}

#main.home {
  .content-anchor {
    @extend .d-block;
    height: calc(50px + ((0.5rem + 0.3125rem) * 2));
  }

  @include media-breakpoint-up(lg) {
    .content-anchor {
      height: calc(80px + ((0.5rem + 0.3125rem) * 2));
    }
  }
}
